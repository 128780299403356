/* You can add global styles to this file, and also import other style files */

:root {
  --white: #ffffff;
  --dark: #012f2d;
  // --background: #eff7f8;
  --background: #eef1f5;
  --element: #d9e0e2;
  --title: #012f2d;
  --text: #465453;
  --grey-nav: #e9e9e9;
  --grey-th: #f0f0f0;
  --prompt: #a8b4b1;
  --border: #edeeef;
  --accessibility: #eff0f0;
  --primary-100: #eff7f8;
  --primary-150: #21cee1;
  --primary-200: #ceebee;
  --primary-300: #089bab;
  --blue-dark: #09122f;
  --light: #f4f7f7;
  --warning-color: #f6a242;
  --grey-detail: #f0f0f0;
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
  --bw-alert-color: #664d03;
  --bw-alert-bg: #fff3cd;
  --bw-alert-border-color: #ffecb5;
}

// PALETTE ------------------------------------ //

$primary-100: #089bab;
$primary-200: #089bab;
$primary-300: #089bab;

$error-100: #ff8181;
$error-200: #fc5656;
$error-300: #e64141;

$warning-100: #ffc881;
$warning-200: #f6a242;
$warning-300: #f7b325;

$valid-100: #16e485;
$valid-200: #00d975;
$valid-300: #02c169;

$info-100: #8ccff2;
$info-200: #5eb6e4;
$info-300: #43aadf;

//Fonts ------------------------------------ //

$Titillium: "Titillium Web", sans-serif, Arial;

h2,
.h2 {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 32px 0 5px 0;
}

.title-page {
  margin-bottom: 25px;
  h1 {
    font-size: 1.85rem;
    font-weight: 700;
    color: var(--title);
  }
  @media (max-width: 991px) {
    padding-left: 0 !important;
  }
}
//main ------------------------------------ //

body {
  background: var(--background);
  font-family: $Titillium;
  font-size: 1rem;
  color: var(--text);
}
//Auth--------------------------------------------//
.card {
  &.card-auth {
    padding: 30px;
    .card-header {
      text-align: center;

      h2 {
        color: #000;
        margin-bottom: 20px;
        text-align: center;
        font-size: 2rem;
      }
    }
    .btn-submit {
      width: 100%;
      padding: 13px 15px !important;
      background-color: $primary-300;
      &:hover {
        background-color: var(--dark) !important;
        opacity: 1;
      }
    }
    .forgetPassword-link {
      text-align: center;
      color: $primary-300;
      display: block;
      padding: 15px 0;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        color: var(--dark) !important;
        transition: all 0.3s ease;
      }
    }
    .alert-danger {
      color: #721c24 !important;
      background-color: #f8d7da !important;
      border-color: #f5c6cb !important;
      width: 100%;
      box-shadow: none !important;
      text-align: center;
    }
  }
}
.error-msg {
  .alert-danger {
    color: #721c24 !important;
    background-color: #f8d7da !important;
    border-color: #f5c6cb !important;
    width: 100%;
    box-shadow: none !important;
    text-align: center;
  }
}
//Nav-----------------------------------------//
nav.navbar {
  transition: all 0.3s ease-in-out;
}
nav.navbar.fixed-navbar {
  position: fixed !important;
  background: var(--white);
  z-index: 999;
  left: initial;
  padding: 5px 0;
  width: calc(100% - 265px);
  box-shadow: 0px 4px 5px 1px rgba(175, 175, 175, 0.15);
  transition: all 0.3s ease-in-out;
  border-radius: 0 0 15px 15px;
  .navbar-nav {
    box-shadow: none !important;
    padding: 0 !important;
    transition: all 0.3s ease-in-out;
  }
  @media (max-width: 991px) {
    width: 100%;
    left: 0;
    padding: 5px 15px;
  }
}
.btn {
  &.btn-nav {
    background: transparent !important;
    box-shadow: none !important;
    color: var(--primary-300) !important;
    outline: none;
    cursor: pointer;
    &:hover {
      color: var(--primary-300) !important;
      background-color: var(--primary-100) !important;
      border-color: var(--primary-100) !important;
      box-shadow: none !important;
    }
    &:active,
    &:focus {
      color: var(--primary-300);
      background-color: var(--primary-100) !important;
      border-color: var(--primary-100);
      box-shadow: none !important;
      &:hover {
        color: var(--primary-300);
        background-color: var(--primary-100) !important;
        border-color: var(--primary-100);
        box-shadow: none !important;
      }
    }
  }
  &.btn-add-row {
    box-shadow: none !important;
    background: var(--background) !important;
    color: var(--primary-300) !important;
    &:hover {
      background: var(--primary-300) !important;
      color: var(--white) !important;
    }
  }
}
.navbar {
  .navbar-toggler {
    height: 40px;
    position: relative;
    z-index: 999;
    .navbar-toggler-icon {
      background: #000;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background: #000;
        top: -8px;
        width: 22px;
        height: 2px;
        left: 0;
      }
      &::after {
        content: "";
        position: absolute;
        background: #000;
        bottom: -8px;
        width: 22px;
        height: 2px;
        left: 0;
      }
    }
  }
  #navigation {
    .navbar-nav {
      background: var(--white);
      padding: 5px 15px;
      border-radius: 25px;
      box-shadow: 0px 4px 5px 1px rgba(175, 175, 175, 0.1);
    }
    @media (max-width: 991px) {
      position: absolute;
      top: 50px;
      right: -100%;
      transition: all 0.3s ease-in-out;
      .navbar-nav {
        padding: 5px;
      }
    }
    &.show {
      @media (max-width: 991px) {
        right: 15px;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}
p-breadcrumb {
  width: calc(100% - 50px);
}
.p-breadcrumb {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  padding: 0.571rem 1rem 0.571rem 0;
  color: var(--title);

  ul {
    flex-wrap: wrap !important;
    li {
      &:last-child {
        font-weight: 600;
      }
      &.p-breadcrumb-chevron {
        margin: 0 0.5rem 0 0.5rem;
        color: var(--title);
        font-size: 0.65rem;
      }
      a {
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          .p-menuitem-icon,
          .p-menuitem-text {
            color: var(--primary-300);
            transition: all 0.3s ease-in-out;
          }
        }
      }
      .p-menuitem-icon {
        color: var(--title);
        margin: 0 5px 0 0;
      }
      .p-menuitem-link:focus {
        box-shadow: none;
      }
    }
  }
}

/*card---------------------------------------*/
.block-sections {
  //padding: 15px;
  padding: 15px 0;
  .p-datatable table {
  }
  @media (max-width: 991px) {
    padding: 15px 0;
  }
}
.top-page-section {
  // padding: 0 15px;
  padding: 0;
  .app-form-body {
    max-height: none !important;
    height: auto !important;
  }
  @media (max-width: 991px) {
    padding: 0;
  }
}
.card {
  cursor: pointer;
  background: var(--white);
  margin: 0 0 6px;
  // box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.04);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: 10px !important;
  border: none;
  .card-header {
    padding: 0 20px 30px;
    margin: 0 !important;
  }
}
.card-title {
  color: var(--primary-300) !important;
  align-items: center !important;
}
.card-infos {
  background: var(--white);
  padding: 15px 15px 15px 25px;
  margin: 0 0 20px;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.04);
  position: relative;
  border-radius: 15px !important;
  position: relative;
  display: flex;
  justify-content: space-between;

  &::before {
    content: "";
    background: var(--primary-300);
    width: 4px;
    position: absolute;
    top: 10px;
    left: 5px;
    bottom: 10px;
  }
  h5 {
    color: var(--primary-300);
    text-transform: capitalize;
    font-weight: 700;
  }
  h6 {
    color: var(--primary-300);
    text-transform: capitalize;
    font-weight: 700;
  }
  .date-utc {
    display: block;
    font-size: 1rem;
    margin-bottom: 5px;
    color: var(--text);
  }
}
p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: var(--text);
}
.section {
  margin: 10px 0px 10px 0px;
  padding: 10px 10px 10px 10px;
  border: 0;
  background: var(--white);
  width: 100%;
  //   box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 4px 5px 1px rgba(175, 175, 175, 0.1);
  position: relative;
  border-radius: 15px !important;
  h2 {
    margin-top: 0;
    color: var(--primary-300);
    font-size: 1.21rem;
  }
}
.header-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    margin-bottom: 5px !important;
  }
  .btn-danger {
    background-color: rgba(244, 67, 54, 0.095) !important;
    color: $error-300 !important;
    border: 1px solid rgba(244, 67, 54, 0.05) !important;
    box-shadow: none !important;
    &:hover {
      background: $error-300 !important ;
      color: var(--white) !important;
    }
  }
}
.card-body-section {
  width: 100%;

  .tabs-wrraper-custom .mat-tab-header {
    margin: 0;
  }
  div.app-form-body {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    // max-height: 100% !important;
    height: auto !important;
  }
}
.dashbord-dody {
  .tabs-wrraper-custom .mat-tab-header {
    margin: 0 0 15px;
  }
}
.card-two-section {
  .section {
    height: calc(100% - 20px);
  }
}
.sections {
  padding: 15px 0 0;
}
.detail-title {
  font-weight: 600;
  color: $primary-300;
  font-size: 1.2rem;
}
.detail-subtitle {
  font-weight: 600;
  color: $primary-300;
  font-size: 1rem;
}
.card-title {
  .header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      margin: 0 !important;
    }
    .btn-just-icon {
      box-shadow: none;
      color: var(--primary-300) !important;
      background-color: var(--primary-100) !important;
      &:hover {
        background-color: var(--primary-300) !important;
        color: var(--primary-100) !important;
      }
    }
  }
  .infos-title {
    padding: 15px 0 0;
    color: var(--warning-color);
    .mat-hint {
      color: var(--warning-color);
    }
  }
}
.card-header {
  h2 {
    margin: 15px 0 0 !important;
    text-align: left;
  }
}
.card-transparent {
  .mat-tab-nav-bar,
  .mat-tab-header {
    border-bottom: none;
  }
  .mat-tab-body-wrapper {
    background: var(--white);
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.04);
    border-radius: 15px !important;
    padding: 25px;
    @media (max-width: 767px) {
      padding: 15px;
    }
  }
  .mat-tab-list {
    text-align: left;
  }
  .mat-tab-labels {
    display: inline-flex;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }
  // .mat-tab-label {
  //   color: var(--text);
  //   padding: 0 35px !important;
  //   opacity: 1;
  //   background: var(--grey-nav);
  //   margin: 0 !important;
  //   font-size: 17px;
  //   font-weight: 600;
  //   &.mat-tab-label-active {
  //     background: var(--white) !important;
  //     color: var(--primary-300);
  //   }
  // }
  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: var(--primary-300);
  }
}
.mat-tab-label,
.mat-tab-link {
  font-family: $Titillium !important;
}
.mat-tab-group {
  font-family: $Titillium !important;
}
.p-component {
  font-family: $Titillium !important;
}
.fc .fc-toolbar .fc-button {
  color: #ffffff;
  background: var(--primary-100);
  border: 1px solid var(--primary-100);
  border-radius: 20px;
  color: var(--primary-300);
}
.fc .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.fc .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.fc .fc-toolbar .fc-button:enabled:hover {
  background: var(--primary-300);
  border-color: var(--primary-300);
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: var(--primary-300);
  border-color: var(--primary-300);
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: var(--primary-100);
  border: 1px solid var(--primary-100);
  color: var(--primary-300);
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: var(--primary-300);
  border-color: var(--primary-300);
  color: #fff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: var(--primary-300);
  border-color: var(--primary-300);
  color: #fff;
}
.fc .fc-button-primary:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none;
}
.fc .fc-toolbar .fc-button:enabled:active:focus {
  box-shadow: none;
}
.section-inner-box {
  height: 80vh;
  @media (max-width: 991px) {
    height: auto;
  }
}
.mat-icon-button {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  outline: 0 !important;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: var(--primary-100);
    transition: all 0.3s ease-in-out;
  }
}
.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(-5%) !important;
}
.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0.45em 0 !important;
}
.suffix-input {
  padding: 0 8px;
  color: var(--primary-300);
  border-left: 2px solid var(--primary-300);
  display: inline-flex;
  line-height: 15px;
  font-size: 0.85rem;
}
.mat-form-field-suffix {
  .suffix-input {
    .mat-icon {
      font-size: 15px;
      height: 15px;
      width: 15px;
      margin-left: 2px;
      cursor: pointer;
      color: var(--warning-color);
    }
  }
}
/*table 
-----------------------------------------*/

.p-datatable .p-datatable-thead > tr > th {
  color: var(--dark);
  background: var(--background);
  border: none;
  padding: 0.371rem 0.857rem !important;
  font-weight: 600 !important;
}
.p-datatable .p-datatable-thead > tr > th:first-child {
  border-radius: 10px 0 0 0;
}
.p-datatable .p-datatable-thead > tr > th:last-child {
  border-radius: 0 10px 0 0;
}
.p-datatable .p-datatable-tfoot > tr > td {
  border-color: #c8c8c8;
  border-width: 1px 0 0 0;
  color: var(--text);
  background: #fff;
}
.p-datatable .p-datatable-tbody > tr > td {
  border-color: var(--border);
  //  border-width: 1px 0 0 0;
  padding: 0.471rem 0.857rem;
  overflow: hidden;
}
div.p-datatable-scrollable-header-box {
  padding-right: 0px !important;
}

div.p-datatable-scrollable-footer-box {
  padding-right: 0px !important;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  color: cornflowerblue;
}
.p-datatable .p-paginator-bottom {
  border-color: transparent !important;
  background-color: var(--white);
  margin-top: 15px;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--primary-300);
  border-color: var(--primary-300);
  color: #ffffff;
  border-radius: 8px;
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: var(--white);
}
table .p-datatable-tbody > tr {
  border: dotted !important;
  border-color: gainsboro !important;
}
.p-datatable-thead > tr > th .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.45em 0 0.55em 0;
  margin-top: 0;
}
.p-datatable-thead > tr > th .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.64375em solid transparent;
}
.p-datatable-thead > tr > th .mat-form-field-appearance-outline .mat-form-field-outline {
  display: flex;
  position: absolute;
  top: 0.25em;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background: #fff;
  border-radius: 5px;
}

.action-table {
  .btn {
    padding: 0 !important;
    height: 30px;
    width: 30px;
    border-radius: 8px !important;
    margin: 0 2px !important;
    background: transparent !important;
    box-shadow: none !important;
    &:hover {
      background: var(--background) !important;
    }
    &.btn-danger {
      color: #eb2d37 !important;
    }
    &.btn-warning {
      color: #ff9800 !important;
    }
    &.btn-primary {
      color: var(--primary-300) !important;
    }
    &.btn-info {
      color: #00bcd4 !important;
    }
  }
}
.btns-action-th {
  //  display: flex;
  //  align-items: center;
  //   width: 100%;
  text-align: left;
}

.mat-dialog-container {
  .section {
    border: 1px solid var(--primary-200);
  }
}
.row-table-expanded-class {
  .section {
    //    background: var(--background) !important;
    box-shadow: none !important;
    margin-bottom: 12px !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    background: var(--white);
  }
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
    background: var(--white);
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    background: var(--white);
  }
  app-patient-pregnancy-baby-history-management {
    .section {
      padding: 0 !important;
    }
  }
}
.mat-chip.mat-standard-chip {
  background-color: var(--primary-200) !important;
  color: var(--primary-300) !important;
}

.header-file-download {
  .p-fileupload .p-fileupload-buttonbar .p-button {
    box-shadow: none !important;
    background: var(--background) !important;
    color: var(--primary-300) !important;
    padding: 8px 15px;
    font-size: 0.95rem;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 8px;
    border: none;
    &:hover {
      background: var(--primary-300) !important;
      color: var(--white) !important;
    }
  }
}
/*forms
---------------------*/

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 3px 5px 10px 0px;
}
.mat-form-field-label,
mat-label {
  color: var(--dark);
}
.top-actions {
  margin: 0 0 5px;
  text-align: right;
}
.mat-form-field {
  font-family: $Titillium;
}
.mat-input-element {
  color: #000;
}
.mat-select {
  font-family: $Titillium !important;
}
/*Button------------------------*/
.mat-button.btn.btn-info,
.mat-raised-button.btn.btn-info,
.mat-raised-button.btn:not([class*="mat-elevation-z"]).btn-info,
.btn.btn-info {
  background-color: var(--primary-300);
  border-color: var(--primary-300);
  box-shadow: none;
}
.btn {
  font-weight: 600 !important;
  &.btn-light {
    background: var(--light) !important;
    color: var(--primary-300) !important;
    border: 1px solid var(--border) !important;
    box-shadow: none !important;
    .material-icons {
      margin: 0 5px;
    }
    &:hover {
      color: var(--white) !important;
      background: var(--primary-300) !important;
    }
  }
}

.btn {
  &.mat-button,
  &.mat-raised-button,
  &.mat-raised-button.btn:not([class*="mat-elevation-z"]) {
    padding: 8px 15px;
    font-size: 0.95rem;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 8px;
  }
}
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font-family: $Titillium !important;
  font-weight: 700;
}
.mat-toolbar h2 {
  font-size: 1.4rem;
}
.mat-dialog-content {
  .app-form-body {
    padding: 10px;
  }
  .section {
    border: 1px solid var(--primary-200);
  }
}
.mat-menu-content {
  padding: 0 !important;
  .mat-menu-item {
    font-family: $Titillium;
    background: var(--primary-100) !important;
    color: var(--primary-300) !important;
    border-radius: 0;
    margin: 5px 0;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: var(--white) !important;
      background: var(--primary-300) !important;
      transition: all 0.3s ease-in-out;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.block-modal-tabs {
  .section {
    position: relative;
    // border: 1px solid var(--primary-200);
  }
  .right-tabs {
    .app-form-body {
      // height: 78vh !important;
      padding-right: 10px;
      @media (max-width: 767px) {
        padding-right: 0;
      }
    }
  }
}
.left-tabs {
  padding: 0;
  .p-sidebar {
    border: none !important;
    box-shadow: none !important;
  }
  .p-sidebar .p-sidebar-header {
    display: none;
  }

  .p-panelmenu .p-panelmenu-panel {
    margin-bottom: 10px;
  }
  .p-panelmenu .p-panelmenu-header {
    &.panel-menu-error {
      background: transparent !important;
      > a {
        color: var(--white) !important;
        background: $error-300 !important;
      }
    }
    &.p-highlight {
      > a {
        background: var(--primary-300) !important;
        border-color: var(--primary-300) !important;
        color: var(--white) !important;
      }
    }
    > a {
      border: none !important;
      color: var(--primary-300) !important;
      background: var(--primary-200) !important;
      font-weight: 700;
      border-radius: 15px !important;
      padding: 20px;
      &:hover {
        background: var(--primary-300) !important;
        border-color: var(--primary-300) !important;
        color: var(--white) !important;
      }
      &:focus {
        box-shadow: none !important;
      }
      @media (max-width: 1199px) {
        padding: 20px 10px;
      }
      @media (max-width: 900px) {
        padding: 20px 5px;
        font-size: 0.85rem;
      }
      @media (max-width: 767px) {
        padding: 20px 15px;
        font-size: 1rem;
      }
    }
  }
  .p-sidebar .p-sidebar-content {
    @media (max-width: 1199px) {
      padding: 0.571rem 0.2rem;
    }
  }
}
//Datepicker------------------------------//

.mat-datepicker-toggle {
  .mat-button-base {
    .mat-button-wrapper {
      color: var(--primary-300);
    }
  }
}
.calender-section {
  padding-top: 20px;
  height: 550px;
  overflow: auto;
  @media (max-width: 991px) {
    height: auto;
  }
}
.section-sales {
  padding-top: 20px;
  min-height: 550px;
  height: auto;
  // overflow: auto;
}
//Dialog -------------------------------------//

.mat-dialog-container {
  border-radius: 8px !important;
  padding: 10px 15px !important;
  overflow: auto !important;
  max-height: 90vh !important;
  .mat-toolbar {
    background: var(--white);
    color: var(--title);
    h4,
    h5,
    h6 {
      font-family: $Titillium !important;
      font-weight: 700;
      font-size: 0.8rem;
    }
  }
}
.mat-dialog-content {
  margin: 0 !important;
  padding: 0 15px !important;
  max-height: 70vh !important;
  height: calc(100% - 75px);
  form {
    margin-bottom: 0 !important;
  }
}
.mat-h2,
.mat-title,
.mat-typography h2 {
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0 0 15px;
  font-family: $Titillium;
  color: var(--primary-300);
}
.mat-h3,
.mat-subheading-2,
.mat-typography h3 {
  font-size: 1.05rem;
  font-family: $Titillium;
  line-height: 18px;
  margin: 0 0 15px;
}
.mat-toolbar-row,
.mat-toolbar-single-row {
  .btn {
    box-shadow: none;
  }
}
input.mat-input-element {
  min-height: 20px !important;
}
textarea.mat-input-element.cdk-textarea-autosize {
  margin-top: -1px !important;
  min-height: 20px !important;
  line-height: 20px !important;
}
.mat-select-trigger {
  min-height: 20px !important;
  height: auto !important;
}
.mat-select-value-text {
  min-height: 20px;
  display: block;
  line-height: 20px;
}
.footer-modal {
  padding: 20px 0 0;
  overflow: hidden;
  .btn {
    margin: 5px !important;
    box-shadow: none !important;
    font-size: 0.85rem !important;
    &.btn-white {
      border: 1px solid var(--border);
      color: var(--text) !important;
    }
  }
}
.cdk-overlay-pane {
  @media (max-width: 992px) {
    // width: 100% !important;
    padding: 0 15px;
  }
}
.mat-option-footer {
  padding: 0 !important;
  text-align: center;
  .btn {
    margin: 0 !important;
    width: 100%;
    border-radius: 0 !important;
  }
}
//note---------------------------------------//

.section-list {
  background: var(--background);
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
  .header-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0 0 15px;
  }
  h3 {
    font-weight: 700;
    color: var(--title);
    font-size: 1.2rem;
  }
}

.file-download-box {
  &.outer-div {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    background: var(--white);
    border-radius: 8px;
    justify-content: flex-start;
    margin-bottom: 8px;
  }
  .download-file-img {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 10px 0 0;
    img {
      width: 50px;
      height: 50px;
    }
  }
  .file-name {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 130px);
  }
  .btn-file-action {
    display: flex;
    margin-top: 10px;
    .btn {
      margin: 0 5px !important;
      width: 35px;
      height: 35px;
      border-radius: 8px;
      /* background: transparent !important;*/
      color: var(--text);
      padding: 5px;
      line-height: 30px;
      box-shadow: none;
      border: none !important;
      &:hover {
        background: var(--background) !important;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.header-file-download {
  .p-fileupload .p-fileupload-buttonbar {
    background: transparent !important;
    padding: 0.857rem 0 !important;
    border: none !important;
    color: #333333;
    border-bottom: 0 none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-fileupload .p-fileupload-content {
    background: transparent !important;
    border: none !important;
  }
}
.sidebar {
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.04);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.sidebar-inner {
  display: grid;
  width: 100%;
  height: 100vh;
  gap: 3px;
  padding: 0 10px;
  grid-template-areas:
    "logo-app"
    "logo-brund"
    "pub-top"
    "pub-bottom"
    "footer";
  grid-template-rows: 50px 50px 2fr 1fr 50px;
  grid-template-columns: 1fr;

  .logo-app {
    grid-area: logo-app;
    border: 2px dashed var(--border);
    color: #000;
    display: grid;
    justify-content: center;
    align-content: center;
    font-weight: 700;
  }
  .logo-client {
    grid-area: logo-brund;
    border: 2px dashed var(--border);
    color: #000;
    display: grid;
    justify-content: center;
    align-content: center;
    font-weight: 700;
  }
  .pub-primary {
    grid-area: pub-top;
    border: 2px dashed var(--border);
    color: #000;
    display: grid;
    justify-content: center;
    font-weight: 700;
    overflow: hidden;
    img {
      object-fit: contain;
      max-height: 55vh;
    }
    @media (max-height: 800px) {
      img {
        max-height: 50vh;
      }
    }
  }

  .pub-secondary {
    grid-area: pub-bottom;
    border: 2px dashed var(--border);
    color: #000;
    display: grid;
    justify-content: center;
    font-weight: 700;
    overflow: hidden;
    img {
      object-fit: contain;
      max-height: 30vh;
    }
    @media (max-height: 800px) {
      img {
        max-height: 25vh;
      }
    }
  }

  .sidebar-footer {
    grid-area: footer;
    padding: 15px 0;
    text-align: center;
    p {
      margin: 0;
      font-size: 12px;
      color: var(--prompt);
    }
  }
}

.logo-box {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 35px;
  color: #000;
  span {
    color: var(--primary-300);
  }
}
.p-carousel-item {
  img {
    cursor: pointer;
  }
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-300);
  border: 0px none var(--white);
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: var(--element);
  border: 1px none var(--white);
  border-radius: 5px;
}

.tabs-wrraper-custom {
  .mat-ink-bar {
    display: none;
  }
  .mat-tab-header {
    //margin: 0 15px;
    margin: 0;
    border-bottom: none !important;

    .mat-tab-list {
      background: var(--primary-300);
      border-radius: 15px;
      padding: 10px;
    }
    .mat-tab-labels {
      display: inline-flex;
      border-radius: 8px 8px 0 0;
      overflow: hidden;
      .mat-tab-label {
        padding: 0 35px !important;
        background: var(--primary-100);
        color: #000;
        margin: 0 5px !important;
        font-size: 17px;
        font-weight: 700;
        border-radius: 25px;
        transition: all 0.3s ease-in-out;
        &:hover {
          background: var(--white) !important;
          color: var(--primary-300) !important;
          transition: all 0.3s ease-in-out;
          opacity: 1;
        }
        @media (max-width: 576px) {
          padding: 0 20px !important;
          font-size: 15px;
        }
      }

      .mat-tab-label-active {
        background: var(--white) !important;
        color: var(--primary-300) !important;
        opacity: 1;
      }
    }
  }
}
.sub-section {
  border-bottom: 1px solid var(--border);
  padding: 10px 0;
}
.sub-section-blue {
  padding: 10px 15px;
  background: var(--primary-100);
  margin-bottom: 15px;
  border-radius: 15px;
}
.sub-section-background,
.section-background {
  border-bottom: 1px solid var(--primary-100);
  background: var(--primary-100);
  padding: 10px 10px 10px 10px;
  border-radius: 15px !important;
  margin: 0px 0px 5px 0px;
  position: relative;
  h2 {
    margin-top: 0;
    font-size: 1.22rem;
  }
  .section {
    margin-top: 10px;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    background: var(--white);
  }
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
    background: var(--white);
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    background: var(--white);
  }
  .close-right {
    right: 15px !important;
    top: 5px !important;
    transform: none !important;
    &.close-box {
      padding-top: 15px;
      position: static;
      .btn {
        padding: 5px 10px !important;
        margin: 0 4px !important;
        &.btn-danger-light {
          position: absolute !important;
          right: -15px;
          top: -25px;
          border-radius: 50% !important;
          padding: 5px !important;
          height: 35px;
          width: 35px;
        }
      }
    }
    &.close-box-r {
      padding-top: 15px;
      right: -15px !important;
      top: 2px !important;
      position: absolute;
      .btn {
        padding: 5px 10px !important;
        margin: 0 4px !important;
        &.btn-danger-light {
          position: absolute !important;
          right: -15px;
          top: -25px;
          border-radius: 50% !important;
          padding: 5px !important;
          height: 35px;
          width: 35px;
        }
      }
    }
  }
  .row-items {
    > .close-right {
      right: 0 !important;
      top: 0 !important;
      .btn {
        margin-left: 7px;
      }
      &.close-box {
        right: -15px !important;
        position: absolute !important;
      }
    }
  }
}
.stimulation-item,
.sub-section-background,
.row-items {
  position: relative;
  padding-right: 40px;
  .close-right {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    .btn-danger-light.btn {
      width: 32px;
      height: 32px;
      padding: 3px !important;
      background-color: rgba(244, 67, 54, 0.095);
      color: $error-300;
      border: 1px solid rgba(244, 67, 54, 0.05);
      box-shadow: none !important;
      &:hover {
        background: $error-300 !important ;
        color: var(--white) !important;
      }
    }
    .btn-success-light {
      width: 32px;
      height: 32px;
      padding: 3px !important;
      background-color: var(--bs-alert-bg);
      color: var(--bs-alert-color);
      border: 1px solid var(--bs-alert-border-color);
      box-shadow: none !important;
      &:hover {
        background: var(--bs-alert-color) !important ;
        color: var(--white) !important;
      }
    }
    .btn-warning-light {
      width: 32px;
      height: 32px;
      padding: 3px !important;
      background-color: var(--bw-alert-bg) !important;
      color: var(--bw-alert-color) !important;
      border: 1px solid var(--bw-alert-border-color) !important;
      box-shadow: none !important;
      &:hover {
        background: $warning-200 !important ;
        color: var(--white) !important;
        border-color: $warning-200 !important;
      }
    }
  }
}
.primeng-table-section {
  .btn-warning-light {
    width: 32px;
    height: 32px;
    padding: 3px !important;
    background-color: var(--bw-alert-bg) !important;
    color: var(--bw-alert-color) !important;
    border: 1px solid var(--bw-alert-border-color) !important;
    box-shadow: none !important;
    &:hover {
      background: $warning-200 !important ;
      color: var(--white) !important;
      border-color: $warning-200 !important;
    }
  }
  .btn-danger-light.btn {
    width: 32px;
    height: 32px;
    padding: 3px !important;
    background-color: rgba(244, 67, 54, 0.095) !important;
    color: $error-300 !important;
    border: 1px solid rgba(244, 67, 54, 0.05) !important;
    box-shadow: none !important;
    &:hover {
      background: $error-300 !important ;
      color: var(--white) !important;
    }
  }
  .date-th-btn {
    margin-left: 5px;
  }
}
.more-infos {
  .btn {
    color: $warning-300 !important;
  }
}
button {
  &:focus {
    outline: none !important;
  }
}
.mat-raised-button.mat-button-disabled {
  pointer-events: none !important;
  cursor: not-allowed !important;
  &:hover {
    background: #a6a6a6 !important;
  }
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  background: #a6a6a6 !important;
}
.btn-danger-light.btn {
  width: 32px;
  height: 32px;
  padding: 3px !important;
  background-color: rgba(244, 67, 54, 0.095);
  color: $error-300;
  border: 1px solid rgba(244, 67, 54, 0.05);
  box-shadow: none !important;
  &:hover {
    background: $error-300 !important ;
    color: var(--white) !important;
  }
}
.btns-right,
.close-box {
  padding-top: 15px;
  .btn {
    padding: 5px 10px !important;
    margin: 0 4px !important;
    &.btn-danger {
      position: absolute !important;
      right: -15px;
      top: -15px;
      border-radius: 50% !important;
      padding: 5px !important;
      height: 35px;
      width: 35px;
    }
  }
}

.tabs-dashboard {
  .mat-tab-labels {
    display: flex;
    padding: 15px 0;
  }
  .mat-tab-label {
    color: var(--primary-300);
    background: var(--primary-200) !important;
    padding: 20px 35px !important;
    margin: 0 10px !important;
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    border-radius: 15px;
    height: auto;
    // box-shadow: 0px 4px 5px 1px rgba(175, 175, 175 ,.1);
    transition: all 0.3s ease-in-out;
    &.mat-tab-label-active,
    &:hover {
      background: var(--primary-300) !important;
      color: var(--white) !important;
      transition: all 0.3s ease-in-out;
      font-weight: 400;
    }
  }

  @media (max-width: 768px) {
    .mat-tab-label {
      padding: 10px 15px !important;
      margin: 0 5px !important;
      font-size: 15px;
    }
  }
  @media (max-width: 479px) {
    .mat-tab-label {
      padding: 10px !important;
      font-size: 14px;
      flex-basis: auto;
    }
  }
}
@media (max-width: 991px) {
  .fc .fc-view-harness-active > .fc-view {
    position: static !important;
  }
  p-fullcalendar .fc .fc-scrollgrid {
    height: 500px;
  }
}
.dashbord-dody {
  @media (max-width: 768px) {
    .card-stats .card-body {
      padding: 0;
    }
    .mat-tab-header-pagination {
      min-width: 20px;
    }
  }
}

@media (max-width: 767px) {
  .mat-dialog-content {
    // max-height: 450px !important;
  }
}

@media (max-width: 767px) {
  .fc .fc-toolbar-title {
    font-size: 1.15em;
  }
  .mat-toolbar-row,
  .mat-toolbar-single-row {
    white-space: normal !important;
    height: auto !important;
  }
  .fc .fc-view-harness {
    overflow-x: auto;
  }
  p-fullcalendar .fc .fc-scrollgrid {
    width: 780px !important;
  }
}

@media (max-width: 576px) {
  .fc .fc-toolbar {
    flex-direction: column;
  }
  .fc .fc-toolbar-title {
    margin: 10px 0 !important;
  }
}

.top-search {
  padding: 0;
  form {
    margin-bottom: 5px;
  }
}

.mat-tooltip {
  color: #fff;
  border-radius: 6px !important;
  margin: 5px 0 0 !important;
  max-width: none;
  font-size: 0.85rem;
  background: #555;
  position: relative;
  overflow: visible !important;
  min-width: 100px !important;
  padding: 5px 7px;
  // &::before {
  //   content: "";
  //   border-left: 8px solid transparent;
  //   border-right: 8px solid transparent;
  //   border-bottom: 8px solid #555;
  //   position: absolute;
  //   top: -8px;
  //   left: 50%;
  //   z-index: 999;
  //   transform: translateX(-50%);
  // }
}
.mat-tooltip-show {
  white-space: pre-line !important;
}

div {
  [class*="col-"] {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
}
.row {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.mat-dialog-title {
  margin: 0px !important;
  position: sticky;
  top: -10px;
  background: #fff;
  z-index: 999;
}
.top-add-btn {
  position: sticky;
  top: 0;
  z-index: 99;
  background: #fff;
}
.footer-modal {
  padding: 5px !important;
  position: sticky;
  bottom: -5px;
  background: #fff;
  z-index: 99;
}

.card-body {
  padding: 0px 1.25rem 1.25rem 1.25rem;
}
.mat-calendar-previous-button {
  &:after {
    border-width: 2px 0 0 2px;
    transform: translateX(2px) rotate(-45deg);
    border-color: var(--primary-300) !important;
    border-style: solid;
    width: 10px;
    height: 10px;
  }
}

.mat-calendar-next-button {
  &:after {
    border-width: 2px 0 0 2px;
    border-color: var(--primary-300) !important;
    border-style: solid;
    width: 10px;
    height: 10px;
    transform: translateX(-2px) rotate(45deg);
  }
}

.mat-calendar-previous-button,
.mat-calendar-next-button {
  &:after {
    top: -4px !important;
    left: -4px !important;
  }
}

.mat-calendar-body-selected {
  background-color: var(--primary-300) !important;
  color: #fff;
}
.mat-calendar-period-button {
  color: var(--primary-300) !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--background) !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--background) !important;
}
.mat-calendar-arrow {
  fill: var(--primary-300) !important;
}
.p-datatable table {
  thead {
    th {
      .cell-text {
        min-height: 35px;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  tr.p-highlight td {
    color: #333333;
    background: #fff3cd;
    font-weight: 600;
  }
  td {
    .cell-text {
      white-space: pre-line;
    }
  }
}
.main-panel {
  &.login-page {
    .main-content {
      margin-top: 0;
      padding: 30px 15px;
      min-height: 100vh;
      display: flex;
      align-items: center;
      @media (max-width: 576px) {
        padding: 30px 0 15px;
      }
    }
  }
}
.btn {
  &.btn-danger-small {
    width: 32px;
    height: 32px;
    padding: 3px !important;
    background-color: rgba(244, 67, 54, 0.095) !important;
    color: $error-300 !important;
    border: 1px solid rgba(244, 67, 54, 0.05) !important;
    box-shadow: none !important;
    &:hover {
      background: $error-300 !important ;
      color: var(--white) !important;
    }
  }
  &.btn-warning-small {
    width: 32px;
    height: 32px;
    padding: 3px !important;
    background-color: var(--white) !important;
    color: #ff9800 !important;
    border: 1px solid var(--white) !important;
    box-shadow: none !important;
    &:hover {
      background: var(--background) !important ;
      border-color: var(--background) !important;
    }
  }
}
.box-show-form {
  padding: 10px;
  border: 1px solid var(--primary-300);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  margin: 20px 0;
  h3 {
    color: var(--primary-300);
    font-weight: 600;
    font-size: 1.1rem;
    margin: 0 0 10px;
  }
}
.padding-50 {
  padding-right: 50px !important;
}

.custom-alert-infos {
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid#ffeeba;
  padding: 6px 10px;
  border-radius: 0 8px 8px 8px;
  margin: 10px 0;
  font-weight: 600;
  line-height: 22px;
  display: inline-flex;
  align-items: center;
  .material-icons {
    margin-left: 8px;
    color: var(--primary-300);
  }
}
table {
  .p-datatable-tfoot .row-footer-class {
    border: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
  }
}
.mat-button.btn.btn-primary,
.mat-raised-button.btn.btn-primary,
.btn.btn-primary {
  color: #fff;
  background-color: var(--primary-300) !important;
  border-color: var(--primary-300) !important;
  box-shadow: none !important;
  &.btn-success {
    background-color: #4caf50 !important;
    border-color: #4caf50 !important;
  }
}

.btn-table {
  width: 30px;
  height: 30px;
  margin: 2px !important;
  padding: 2px !important;
  text-align: center;
  .material-icons {
    margin: 0;
  }
}
.btn-moove {
  width: 30px;
  height: 30px;
  margin: 2px !important;
  padding: 2px !important;
  text-align: center;
  line-height: 24px;
  border-radius: 8px;
  color: var(--primary-300);
  background: #fff;
  border: 1px solid var(--primary-200);
  &:hover {
    background: var(--primary-300);
  }
  .material-icons {
    margin: 0;
  }
}
.btn-table-actions {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.857rem;
  margin-right: -0.857rem;
  justify-content: center;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: 0px;
  bottom: initial;
  background: var(--warning-color);
  z-index: 99;
  font-size: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: var(--primary-300) !important;
}
.mat-menu-panel {
  min-height: 50px !important;
  .mat-menu-item {
    > div {
      &:hover {
        color: #fff !important;
      }
    }
  }
}
.mat-menu-content .mat-menu-item {
  &:first-child {
    margin-top: 0;
  }
}

.mat-menu-panel {
  min-height: 40px !important;
  .mat-progress-spinner {
    margin: 0 auto;
    circle {
      stroke: var(--primary-300);
    }
  }
}

.mat-checkbox-layout {
  color: #000;
  white-space: normal !important;
  .mat-checkbox-label {
    font-size: 14px;
    line-height: 16px;
  }
}

.p-datatable-scrollable-wrapper {
  @media (max-width: 1300px) {
    overflow: auto;
    .p-datatable-scrollable-header {
      width: 1150px;
      .p-datatable-thead {
        th {
          width: auto !important;
        }
      }
    }
    .p-datatable-scrollable-body {
      width: 1150px;
      .p-datatable-tbody > tr > td {
        width: auto !important;
      }
    }
    .p-datatable-scrollable-footer {
      width: 1150px;
      .p-datatable-tfoot tr td {
        width: auto !important;
      }
    }
  }
  @media (max-width: 1199px) {
    .p-datatable-scrollable-header {
      width: 1000px;
      .align-center {
        display: inline-block;
      }
    }
    .p-datatable-scrollable-body {
      width: 1000px;
    }
    .p-datatable-scrollable-footer {
      width: 1000px;
    }
  }
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--primary-300);
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--primary-300);
}

.all-carde-dashboarad {
  .card {
    iframe {
      width: 100%;
    }
    .description {
      padding: 0;
      p {
        margin-bottom: 10px;
      }
    }
    .img-card {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

gridster {
  background: transparent !important;
  app-card {
    width: 100%;
  }
  app-chart {
    width: 100%;
  }
}
.gridster-item {
  background: var(--white);
  margin: 0 0 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px !important;
  border: none;
//  height: auto !important;
  .table-wrap {
    max-height: 600px;
    overflow: auto;
    padding: 0 10px 17px;
  }
  .card-header {
    padding: 0 25px 5px;
    margin: 0 !important;
    border-bottom: none;
    background: transparent;
    h2 {
      color: var(--primary-300) !important;
      font-size: 1.21rem;
      margin-bottom: 0;
    }
  }
//  .card-body {
//    padding: 0 15px;
//  }
//  @media (min-width: 1550px) {
//    min-height: 665px;
//  }
//  @media (max-width: 1199px) {
//    max-height: 100%;
//    overflow: auto;
//  }
}

.stimulation-item {
  &.p-end {
    padding-right: 50px !important;
  }
}

mat-dialog-container {
  app-fertility-controlled-ovarian-hyperstimulation-management {
    .app-form-body {
      max-height: 70vh !important;
      height: calc(100% - 75px);
    }
  }
}
app-fertility-controlled-ovarian-hyperstimulation-days-table {
  .btns-action-th {
    text-align: center;
  }
}

mat-dialog-container > app-fertility-controlled-ovarian-hyperstimulation-add-edit-popup {
  @media (max-width: 991px) {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .p-sidebar-content {
    overflow-y: hidden;
  }
}

.p-paginator .p-paginator-pages .p-paginator-page {
  border-radius: 8px;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  border-radius: 8px;
}
.p-paginator-bottom {
  button {
    &:focus {
      box-shadow: none;
      border: none;
    }
  }
}

/*chat-list*/
#ng-chat-search_friend {
  display: block;
  padding: 7px 10px;
  margin-top: 10px;
  font-size: 1rem !important;
  border: none;
  background: #f2f2f2 !important;
  border-radius: 5px;
  color: #000;
}
#ng-chat.light-theme .primary-text,
#ng-chat.light-theme {
  color: #5c5c5c;
  font-family: $Titillium !important;
}
#ng-chat-users li .ng-chat-friends-list-container > strong {
  float: left;
  line-height: 40px !important;
  font-size: 1em !important;
  max-width: 57%;
  max-height: 45px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #000;
  font-weight: 600;
  padding-left: 8px;
}
.ng-chat-participant-status.online {
  background-color: green !important;
}
#ng-chat-users li .ng-chat-friends-list-container > .icon-wrapper {
  background-color: #e4e6eb !important;
  overflow: hidden;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px;
  text-align: center;
}
#ng-chat-users li {
  clear: both;
  margin-bottom: 0 !important;
  overflow: hidden;
  cursor: pointer;
  max-height: none !important;
}
#ng-chat-people {
  position: relative;
  width: 240px !important;
  height: 460px !important;
  border: none !important;
  border-radius: 8px;
  margin-right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  margin-bottom: 75px !important;
  margin-right: 30px;
}
.ng-chat-title-visibility-toggle-area {
  .ng-chat-participant-status {
    width: 10px !important;
    height: 10px !important;
    margin-top: 18px !important;
  }
}
.ng-chat-close {
  background: url(../src/assets/img/close-icons.svg) no-repeat center;
  background-size: 12px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  top: 5px;
  position: relative;
  text-indent: -99999px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #fff;
    transition: all 0.2s ease-in-out;
  }
}
.ng-chat-window .ng-chat-messages .ng-chat-message .message-sent-date {
  font-weight: 600;
  font-size: 0.95rem !important;
  color: var(--primary-300);
}
#ng-chat-users.offset-search {
  top: 120px !important;
}
#ng-chat-people {
  .ng-chat-title {
    height: 50px !important;
    line-height: 50px !important;
    font-size: 1rem !important;
    font-weight: 600;
    border-bottom: 2px solid #e4e6eb;
    border-radius: 8px 8px 0 0;
    position: relative;
    &:after {
      content: "";
      width: 8px;
      height: 8px;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      position: absolute;
      right: 15px;
      top: 18px;
      border-color: var(--primary-300);
      border-style: solid;
      z-index: -1;
    }
  }
}
.ng-chat-title,
.ng-chat-title:hover {
  height: 50px !important;
  line-height: 50px !important;
  font-size: 1rem !important;
  font-weight: 600;
  border-bottom: 2px solid #e4e6eb;
  border-radius: 8px 8px 0 0;
  position: relative;

  select {
    border: none !important;
    height: 40px;
    background: transparent;
    position: relative;
    width: 100%;
    padding-right: 15px;
    font-weight: 600;
    color: var(--primary-300);
    cursor: pointer;
  }
}
#ng-chat.light-theme .ng-chat-window.ng-chat-window-collapsed {
  height: 50px !important;
 
  .ng-chat-title .ng-chat-title-visibility-toggle-area {
    background: transparent !important;
    > strong {
      color: #fff;
    }
  }
}
.ng-chat-title .ng-chat-title-visibility-toggle-area {
  line-height: 45px !important;
  height: 48px;
  z-index: 99;
  position: relative;
  background: var(--primary-100) !important;
}
.ng-chat-friends-list-container {
  position: relative;
  padding: 5px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  .ng-chat-participant-status {
    display: inline-block;
    border-radius: 25px;
    width: 14px;
    height: 14px;
    margin-top: 10px;
    position: absolute;
    left: 30px;
    bottom: 5px;
    border: 2px solid #fff;
  }
  &:hover {
    background-color: #f2f2f2;
    transition: all 0.3s ease-in-out;
  }
}
.ng-chat-title .ng-chat-title-visibility-toggle-area > strong {
  font-weight: 600;
  height: auto !important;
  max-width: 85%;
  color: #012f2d !important;
}
#ng-chat.light-theme .secondary-background {
  background-color: var(--primary-100) !important;
}
#ng-chat.light-theme .ng-chat-window {
  border: none !important;
  background: #fff;
  box-shadow: 0 5px 13px rgba(0, 0, 0, 0.2) !important;
  border-radius: 8px 8px 0 0;
}
.ng-chat-window .ng-chat-footer {
  padding: 5px 10px !important;
  height: 50px;
  border: none !important;
}
.ng-chat-window .ng-chat-messages {
  height: calc(90% - 60px) !important;
}
.ng-chat-window .ng-chat-footer > input {
  font-size: 1rem !important;
  padding: 5px 10px !important;
  display: block;
  height: 45px !important;
  background: #e4e6eb !important;
  border-radius: 50px;
}
.ng-chat-window .ng-chat-messages .ng-chat-message > div {
  font-size: 1rem !important;
}
#ng-chat.light-theme .sent-chat-message-container,
#ng-chat.light-theme .file-message-container {
  background-color: var(--background) !important;
  border-color: var(--background) !important;
  color: #000 !important;
  width: auto !important;
  max-width: 85%;
}
.all-carde-dashboarad {
  > div[class*="col-"] {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
.float-button {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 50px !important;
  height: 50px !important;
  z-index: 999;
  border: none;
  background: var(--primary-300);
  color: #fff;
  border-radius: 50%;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  .material-icons {
    font-size: 28px;
  }
  &:hover{
    background-color: var(--primary-100);
    color: var(--primary-300);
    transition: all 0.3s ease-in-out;
  }
}

#widget-chart {
  apx-chart {
    > div {
      min-height: 450px !important;
    }
  }
}

.table-gridster {
  .table-wrap {
    overflow: auto;
    max-height: 100%;
  }
}
#ng-chat-users li .ng-chat-friends-list-container > strong { 
  max-width: 90% !important; 
}
.btn-warning-light {
    margin-top: 10px;
    width: 50px;
    height: 32px;
    padding: 3px !important;
    background-color: var(--bw-alert-bg) !important;
    color: var(--bw-alert-color) !important;
    border: 1px solid var(--bw-alert-border-color) !important;
    box-shadow: none !important;
    border-radius: 10px;
     &:hover {
        background: $warning-200 !important ;
        color: var(--white) !important;
        border-color: $warning-200 !important;
      }
}

.p-card .p-card-body {
	padding: 0.8rem !important;
}
